@import 'styles/mixins/fonts';
@import 'styles/mixins/media-queries';
@import 'styles/constants';
@import 'styles/mixins/button-styles-2023';
@import 'styles/colors-2023';
@import 'styles/mixins/fonts-2023';

.container {
	display: flex;
	flex-direction: column;

	position: fixed;
	z-index: $z_index_header + 1;
	top: 0;
	right: 0;
	left: 0;

	color: $neutral-white;

	min-width: 280px;
	max-width: 100vw;

	transition: background-color 0.25s ease;

	* {
		transition: all 0.25s ease;
	}

	&:hover {
		background-color: black !important;
	}
}

.spacer {
	background: black;
	display: block;
	// min-height: 70px;
	height: calc(var(--promo-height) + 95px);

	@include sm-down {
		&.without_subnav {
			height: calc(var(--promo-height) + 53px);
		}
	}

	@include md-up {
		height: calc(var(--promo-height) + 70px);
	}
}

.hide_spacer_desktop {
	@include md-up {
		display: none !important;
	}
}

.space_no_nav_links {
	background: black;
	display: block;
	min-height: 64px;
	height: calc(var(--promo-height) + 64px);

	@include md-up {
		height: calc(var(--promo-height) + 70px);
	}
}

.promo_bar {
	position: relative;
	background: $bfcm-2023-bg;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 42px;
	padding: 6px;
	font-weight: 500;

	z-index: -1;

	column-gap: 0;
	flex-direction: column;
	row-gap: 3px;

	@include xs-only {
		row-gap: 0;

		.promo_text {
			@include body7;
			overflow: hidden;
			font-weight: 500;
		}
	}

	overflow: hidden;

	.slider:not(.marquee) {
		opacity: 0;
	}

	&.shown .slider {
		opacity: 1;
		transition: opacity 0.1s ease-in-out 0.4s;
	}

	a,
	p {
		color: #fff;
	}
}

.promo_center {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 5px;
	height: 100%;
}

.promo_bar_collapsed {
	min-height: 0;
	max-height: 0;
	padding-top: 0;
	padding-bottom: 0;
	visibility: hidden;
}

.promo_text {
	white-space: nowrap;

	@include body6;
	text-align: center;
	margin: 0;

	color: black;
	font-weight: 500;
	display: inline-flex;

	&:focus {
		outline: none;
		text-decoration: underline;
	}

	> strong {
		text-decoration: underline;
		margin-left: 5px;
	}
}

.close_button {
	position: absolute;
	left: 1rem;
	background: none;
	padding: 0;
	margin: 0;
	outline: 0;
	border: none;
	cursor: pointer;
	width: 1rem;
	height: 1rem;

	@include sm-down {
		left: 1rem;
	}
}

.hamburger {
	display: flex;
	margin-right: 0;
	width: 0;
	visibility: hidden;
	@include sm-down {
		width: 28px;
		margin-right: 13px;
		visibility: visible;
	}

	justify-content: center;
	align-items: center;

	cursor: pointer;
}

.logo {
	display: inline-block;
	width: 46px;

	@include md-up {
		width: 56px;
	}
}

.desktop_nav_container {
	height: 100px;
	width: 100%;
	align-self: flex-end;
	display: grid;
	padding: 0 80px;
	grid-template-columns: 56px 1fr 56px auto auto;
	gap: 25px;
	align-items: center;
	position: relative;

	@include sm-down {
		display: none;
	}

	&.mounted .desktop_items {
		opacity: 1;
		transition: opacity 500ms ease-in-out;
	}
}

.mobile_nav_container {
	display: none;

	width: 100%;

	@include sm-down {
		display: unset;
	}
}

.mobile_upper {
	padding: 10px 9px;
	display: flex;
	align-items: center;
}

.mobile_spacer {
	flex-grow: 1;
}

.mobile_lower {
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
	justify-content: space-around;
	max-width: 600px;
	margin-inline: auto;
	opacity: 0;
	overflow: auto;

	&.reduced_list {
		justify-content: space-evenly;
	}

	&.mounted {
		opacity: 1;
		transition: opacity 500ms ease-in-out;
	}
}

.desktop_items_wrapper {
	height: 100%;
}

.desktop_items {
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0;
	margin: 0 auto;
	opacity: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	.hide {
		display: none;
	}
}

.nav_bar_item {
	max-width: 200px;
	padding: 2px 0;
	margin: 0 15px;

	@include sm-down {
		height: auto;
		padding: 10px 0;
	}

	position: relative;
	display: flex;
	align-items: center;
	color: white;
	text-decoration: none;
	visibility: visible;

	font-size: 0.875rem;
	font-weight: 400;
	white-space: nowrap;

	cursor: pointer;

	&:hover {
		opacity: 0.6;
		text-decoration: none;
	}
}

.nav_bar_item_highlight {
	box-shadow: rgb(23 96 255 / 50%) 0 2px 0 0;
}

.cart_container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;

	@include sm-down {
		width: 28px;
	}
}

.cart_icon {
	position: relative;
}

.cart_number {
	position: absolute;
	background-color: $brand-secondary;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	bottom: 10px;
	right: 5px;

	font-size: 11px;
	font-weight: 700;
	line-height: 150%;
	text-align: center;

	@include sm-down {
		bottom: 0;
		right: 0;
	}
}

.shade_closed {
	position: fixed;
	inset: 0;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: black;
	opacity: 0;
	pointer-events: none;
	z-index: -1;
}

.shade_open {
	opacity: 0.7;
	z-index: 1;
}

.slider {
	max-width: 620px;
	margin: 0 auto;

	:global(.slick-slide) {
		justify-content: center;

		div {
			display: flex;
			align-items: center;
			height: 100%;
		}
	}

	button:global(.prev_arrow),
	button:global(.next_arrow) {
		width: 20px;
		height: 20px;
		border: none;
		background-color: transparent;
		background-size: cover;

		&:hover,
		&:focus {
			background-color: transparent;
		}
	}

	button:global(.prev_arrow):global(.prev_arrow) {
		left: 0;
		background-image: url('https://eightsleep.imgix.net/icons_chevron_left.svg?v=1650776727');
	}

	button:global(.next_arrow):global(.next_arrow) {
		right: 0;
		background-image: url('https://eightsleep.imgix.net/icons_chevron_right.svg?v=1650776727');
	}
}

.mobile {
	overflow: hidden;
	@include sm-up {
		display: none;
	}
}

.marquee {
	span {
		display: inline-block;
		margin: 0 20px;
	}
}

.cta {
	white-space: nowrap;
}

.anchor_link {
	white-space: nowrap;
	@include button-base;
	@include button-secondary;
	@include button-compact;
}

.zowie {
	margin: 0 1rem !important;

	svg {
		width: 1.25rem !important;
		height: 1.25rem !important;
	}
}

.single_element {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.promo_inner_text_desktop {
	@include xs-only {
		display: none;
	}
}

.promo_inner_text_mobile {
	@include sm-up {
		display: none;
	}
}

.login_button {
	svg {
		height: 1.5rem !important;
		width: 1.5rem !important;
	}
}

@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts';
@import 'styles/mixins/theme';
@import 'styles/colors';
@import 'styles/mixins/fonts-2023';
@import 'styles/colors-2023';

.container {
	padding: 0;
	background-color: transparent;
	display: inline-flex;
	justify-content: center;
	color: $neutral-white;
}

.bell {
	margin-right: 4px;
	position: relative;
	top: -2px;
}

.right_side {
	display: flex;
	flex-direction: column;
}

.upper_container {
	display: flex;
	margin-bottom: 0;
}

.dates {
	display: flex;
	padding: 0 0.25rem;
}

.label {
	font-size: 14px;
	line-height: 1.4;
	margin-right: 5px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	min-width: 53px;

	@include xs-only {
		font-size: 12px;
		min-width: 0;
	}
}

.date_unit_container {
	display: flex;
	margin-right: 6px;

	.number {
		font-size: 14px;
		line-height: 20px;
		padding: 0 4px;
		margin: 0 3px 0 0;
		border-radius: 0.125rem;

		background: rgba(255, 255, 255, 0.2);
		font-weight: 600;

		@include xs-only {
			font-size: 12px;
		}
	}

	.unit {
		font-weight: 500;
		font-size: 10px;
		line-height: 200%;
		margin: 0;
	}
}

.lower_container {
	p {
		max-width: 322px;
		color: #797c8e;
		margin: 0;

		font-weight: 400;
		font-size: 12px;
		line-height: 140%;
	}
}

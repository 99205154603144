@import 'styles/mixins/fonts';
@import 'styles/colors';
@import 'styles/mixins/media-queries';

.container {
	background: black;

	top: 0;
	left: 0;

	width: 100%;
	max-width: 375px;
	height: 100%;
	position: fixed;
	opacity: 0;
	z-index: 99;
	overflow: auto;

	* {
		transition: all 0.25s ease;
	}

	transform: translateX(-100%);
	visibility: hidden;
}

.inner {
	padding: 20px;
	padding-top: 60px;

	display: flex;
	flex-direction: column;
	height: 100%;
}

.container_open {
	transition: transform 0.25s ease;
	transform: translateX(0);
	visibility: visible;
	opacity: 1;
}

.close_button {
	width: 28px;
	height: 28px;
	overflow: hidden;
	position: absolute;
	background: black;
	left: 20px;
	top: 20px;
}

.close {
	max-height: 0 !important;
	margin: 0 !important;
	visibility: hidden;
}

.top_level_products {
	display: flex;
	justify-content: space-between;

	max-height: 120px;

	img {
		width: 120px;
		height: 68px;
		object-fit: cover;
		border-radius: 3px;
		margin-bottom: 12px;
	}
}

.links {
	display: flex;
	flex-direction: column;
}

.link_large {
	font-size: 28px;
	line-height: 130%;
	color: white;

	margin-top: 30px;

	&:last-of-type {
		margin-bottom: 30px;
	}
}

.link_small {
	font-weight: 700;
	font-size: 16px;
	line-height: 140%;
	color: $ui-black;
	margin-bottom: 22px;
	max-height: 30px;

	overflow: hidden;
}

.mobile_nav_drawer_handle {
	position: relative;
	visibility: visible;
	overflow: hidden;
	max-height: 30px;

	display: flex;
	align-items: center;

	picture {
		height: 17px;
		position: absolute;
		left: 250px;
		transform: rotate(180deg);
	}

	&:hover {
		text-decoration: underline;
	}
}

.mobile_nav_drawer_handle_open {
	font-size: 20px;
	margin-bottom: 20px;
	padding-left: 20px;

	margin-top: 0;

	picture {
		left: 0;
		transform: rotate(0);
	}
}

.mobile_drawer {
	max-height: 0;
	visibility: hidden;
	overflow: hidden;

	display: flex;
	flex-direction: column;

	> div {
		padding: 18px 0;
		border-bottom: #ececec 1px solid;
	}

	span {
		display: flex;
		align-items: center;

		font-weight: 700;
		font-size: 14px;
		color: $ui-black;

		img {
			width: 80px;
			height: 46px;
			margin-right: 12px;

			object-fit: cover;

			border-radius: 3px;
			overflow: hidden;
		}

		p {
			font-weight: 400;
			font-size: 14px;
			line-height: 140%;
			margin: 0;
			opacity: 0.6;
		}
	}
}

.mobile_drawer_open {
	visibility: visible;
	max-height: 100vh;
}

.view_all_accessories {
	margin-top: 18px;
	font-weight: 500;
	font-size: 16px;
	color: $ui-black;
}

.line {
	width: 100%;
	max-height: 1px;
	border-bottom: #ececec 1px solid;
	margin: 22px 0;
}

.line_close {
	max-height: 0;
	opacity: 0;
	margin: 0;
}

.mobile_nav_cta {
	background: $brand-primary;

	&:hover {
		text-decoration: none;
		background: $brand-secondary;
	}

	color: $ui-white;

	width: 100%;

	display: flex;
	justify-content: center;
	align-items: center;

	border-radius: 3px;
	padding: 18px;

	text-transform: uppercase;

	font-weight: 700;
	font-size: 14px;

	letter-spacing: 0.5px;
}

.spacer {
	flex: 1;
}

.container {
	border-top: 1px solid white;
	padding: 22px 0;
}
